import T from "prop-types";
import { BodyBold, Caption } from "components/atoms/Typography";
import theme from "config/theme";
import CustomIcon from "components/atoms/CustomIcon";
import { StyledBox } from "./ServiceBox.styles";

function ServiceBox({ title, text, icon }) {
  if (!title || !text || !icon) {
    return null;
  }

  return (
    <StyledBox data-test-id="service-box-wrapper">
      <CustomIcon name={icon} sx={{ fontSize: "2rem" }} />
      <BodyBold
        textAlign="center"
        sx={{ lineHeight: "1.21rem", my: theme.spacing(2) }}
      >
        {title}
      </BodyBold>
      <Caption as="p" textAlign="center">
        {text}
      </Caption>
    </StyledBox>
  );
}

ServiceBox.propTypes = {
  icon: T.string.isRequired,
  title: T.string.isRequired,
  text: T.string.isRequired,
};

export default ServiceBox;
