import T from "prop-types";
import Image from "next/image";
import { useTranslations } from "next-intl";

import { Grid } from "components/MUI";
import Link from "components/Link";

function ManufacturerList({ manufacturers }) {
  const t = useTranslations();

  return (
    <Grid container justifyContent="center" columnSpacing={5} rowSpacing={1}>
      {manufacturers.map((manufacturer) => (
        <Grid key={manufacturer.id} item>
          <Link href={`${t("links.search.link")}?q=${manufacturer.name}`}>
            <Image
              width={200}
              height={150}
              src={manufacturer.logo.url}
              alt={manufacturer.logo.alt}
              title={manufacturer.logo.title}
              style={{
                objectFit: "contain",
                objectPosition: "center",
              }}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

ManufacturerList.propTypes = {
  manufacturers: T.arrayOf(T.shape({})).isRequired,
};

export default ManufacturerList;
