import { useTranslations } from "next-intl";
import { Grid } from "components/MUI";

import theme from "config/theme";
import { H2 } from "components/atoms/Typography";
import Section from "components/Layout/Section";
import ServiceBox from "components/atoms/ServiceBox";

function AlwaysInclusiveSection() {
  const t = useTranslations();

  return (
    <Section>
      <Grid container spacing={{ xs: theme.spacing(5), md: theme.spacing(4) }}>
        <Grid
          item
          md={3}
          xs={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <H2 variant="h1">{t("always_inclusive.h2")}</H2>
        </Grid>
        <Grid item md={3} xs={12}>
          <ServiceBox
            icon="maintenance"
            title={t("always_inclusive.maintenance_title")}
            text={t("always_inclusive.maintenance_text")}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ServiceBox
            icon="replacement"
            title={t("always_inclusive.replacement_title")}
            text={t("always_inclusive.replacement_text")}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <ServiceBox
            icon="service"
            title={t("always_inclusive.service_title")}
            text={t("always_inclusive.service_text")}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

export default AlwaysInclusiveSection;
