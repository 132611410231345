import T from "prop-types";
import { useTranslations } from "next-intl";
import { NextSeo } from "next-seo";

import withPageStaticProps from "helpers/withPageStaticProps";
import Section from "components/Layout/Section";
import AlwaysInclusiveSection from "components/sections/AlwaysInclusiveSection";
import ContactUs from "components/sections/ContactUs";
import { getProducts } from "api/product";
import { getManufacturers } from "api/manufacturer";
import { CMS_API_TOKEN, CMS_API_URL } from "config/system";
import {
  generateShortViewProductData,
  useFormattedShortViewProducts,
} from "helpers/products";
import {
  getConvertedManufacturers,
  getEnrichedCategories,
} from "helpers/categories";
import { convertStrapiData } from "helpers/utils";
import theme from "config/theme";
import GridButtonView from "components/molecules/CategoryDisplay/GridButtonView";
import CarousellView from "components/molecules/ProductDisplay/CarousellView";
import ManufacturerList from "components/molecules/CategoryDisplay/ManufacturerList";

export default function ErrorPage({
  navigationData: categories,
  products: rawProducts,
  manufacturers,
}) {
  const t = useTranslations();

  const products = useFormattedShortViewProducts(
    rawProducts,
    "generic_product_display"
  );

  const enrichedCategories = getEnrichedCategories(categories, t);

  return (
    <>
      <NextSeo
        title={t("seo.title")}
        description={t("seo.description")}
        noindex
        nofollow
      />
      {enrichedCategories?.length > 0 && (
        <Section
          title={t("page.h2_search_via_category")}
          titleStyleProps={{ align: "center", sx: { mb: theme.spacing(4) } }}
        >
          <GridButtonView categories={enrichedCategories} />
        </Section>
      )}
      {manufacturers?.length && (
        <Section
          title={t("page.h2_search_via_manufacturer")}
          titleStyleProps={{ align: "center", sx: { mb: theme.spacing(3) } }}
        >
          <ManufacturerList manufacturers={manufacturers} />
        </Section>
      )}
      <Section
        title={t("page.h2_popular_products")}
        titleStyleProps={{ align: "center", sx: { mb: theme.spacing(4) } }}
      >
        <CarousellView products={products} />
      </Section>
      <AlwaysInclusiveSection />
      <ContactUs
        variant="light"
        anchor={t("contact_anchor")}
        contactUs={{
          title: t("page.contact_us.h2"),
          text: t("page.contact_us.text"),
        }}
        emailBtn={{
          text: t("page.contact_us.email_text"),
          email: t("page.contact_us.btn_email"),
          linkText: t("page.contact_us.btn_email_text"),
        }}
        phoneBtn={{
          text: t("page.contact_us.phone_text"),
          number: t("page.contact_us.btn_phone"),
          linkText: t("page.contact_us.btn_phone_text"),
        }}
      />
    </>
  );
}

ErrorPage.propTypes = {
  navigationData: T.arrayOf(T.shape({})),
  products: T.arrayOf(T.shape({})),
  manufacturers: T.arrayOf(T.shape({})),
};

ErrorPage.defaultProps = {
  navigationData: [],
  products: [],
  manufacturers: [],
};

export const getStaticProps = withPageStaticProps(
  async ({ locale, locales }, sharedPageStaticProps) => {
    const headers = {
      Authorization: `bearer ${CMS_API_TOKEN}`,
    };

    const [products, manufacturers] = await Promise.all([
      getProducts(
        {
          "[fields][0]": "name",
          "[fields][1]": "short_description",
          "[fields][2]": "slug",
          "[fields][3]": "lowest_price_net_",
          "populate[images]": "*",
          "populate[product_group][fields][0]": "name",
          "populate[product_group][fields][1]": "slug",
          "populate[base_price][fields][0]": "currency",
          "populate[manufacturer][fields][0]": "name",
          "filters[carousell][error][$eq]": true,
          locale,
        },
        CMS_API_URL,
        {
          headers,
        }
      ),
      getManufacturers(
        {
          "[fields][0]": "name",
          "[fields][1]": "id",
          "populate[logo]": "*",
          locale,
        },
        CMS_API_URL,
        {
          headers,
        }
      ),
    ]);

    return {
      props: {
        ...sharedPageStaticProps.props,
        products: generateShortViewProductData(products?.data || []),
        manufacturers: getConvertedManufacturers(
          convertStrapiData(manufacturers?.data || [])
        ),
        messages: {
          ...(await import(`../locales/${locale}/404.json`)).default,
          ...(await import(`../locales/${locale}/generic.json`)).default,
        },
        locale,
        locales,
      },
      revalidate: 60,
    };
  }
);
