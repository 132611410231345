import { Box, styled } from "components/MUI";

import theme from "config/theme";

const StyledBox = styled(Box)(() => ({
  background: theme.palette.custom.gradient,
  padding: theme.spacing(3),
  minHeight: "188px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

// eslint-disable-next-line import/prefer-default-export
export { StyledBox };
